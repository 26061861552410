






















import { Component, Vue, Watch } from 'vue-property-decorator';
import { format, getYear } from '@/filter/dateFormat';
import merge from 'lodash.merge';
import { darkTheme } from '@/plugins/highcharts';

interface Options {
  chart: { type: string };
  title: { text: string };
  tooltip: { crosshairs: boolean, pointFormat: string, headerFormat: string, shared: boolean };
  plotOptions: { line: { dataLabels: { enabled: boolean } } };
  xAxis: { categories: string[], title: { text: string } };
  yAxis: { min: number, max: number, title: { text: string } };
  series: Array<{ name: string, data: { y: number, formatted: string, weekHours: number, blocked: number } }>;
  colors: string[];
}

@Component
export default class CarWorkload extends Vue {
  waiting: boolean = false;

  year: string = getYear(Date.now()).toString();

  options: Options = this.initOptions();

  get allOptions() {
    if (!this.$vuetify.theme.dark) {
      return this.options;
    }

    return merge(darkTheme(), this.options)
  }

  initOptions(): Options {
    return {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Statistik: Auslastung der Fahrzeuge'
      },
      tooltip: {
        headerFormat: '<span style="font-size: 10px">Kalenderwoche {point.key}</span><br/>',
        pointFormat: '<span style="color:{point.color}">\u25CF</span>{series.name}: <b>{point.formatted} % ({point.blocked} von {point.weekHours} Stunden)</b><br/>',
        crosshairs: true,
        shared: true
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      xAxis: {
        categories: [],
        title: {
          text: 'Kalenderwoche'
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: 'Auslastung in %'
        }
      },
      series: [],
      colors: ['#b71c1c', '#006064', '#2e7d32']
    };
  }

  @Watch('year', { immediate: true })
  async onYear() {
    this.options = this.initOptions();

    const response = await this.$http.get('api/statistic/car-workload', {
      responseType: 'json',
      params: { year: this.year }
    });

    const { cws, sum, ...cars } = response.data;

    const weekHours = await this.weekHours(cws);

    this.options.title.text = this.options.title.text + ' ' + this.year;
    this.options.xAxis.categories = cws.map((cw: string) => cw.toString().replace(this.year, ''));

    for (const car in cars) {
      if (!cars.hasOwnProperty(car)) continue;

      this.options.series.push({
        name: car,
        data: cars[car].map((hours: number, index: number) => ({
          y: Number(((hours / weekHours[index]) * 100).toFixed(2)),
          blocked: hours,
          weekHours: weekHours[index],
          formatted: ((hours / weekHours[index]) * 100).toFixed(2)
        }))
      });
    }

    setTimeout(() => {
      this.waiting = true;
    }, 500);
  }

  async weekHours(cws: string[]) {
    const response = await this.$http.get(`https://feiertage-api.de/api/?jahr=${this.year}&nur_land=SN`);

    const weekHours: { [cw: string]: number } = cws.reduce((acc: { [cw: string]: number }, cw: string) => {
      acc[cw] = 40;

      return acc;
    }, {});

    (Object.values(response.data) as Array<{ datum: string, hinweis: string }>)
      .filter((day) => day.hinweis === '')
      .forEach((day) => {
        const cw = Number(`${format(day.datum, 'yyyy')}${format(day.datum, 'ii').padStart(2, '0')}`);

        weekHours[cw] -= 8;
      });

    return Object.values(weekHours);
  }

  get years(): string[] {
    const currentYear = new Date().getFullYear();
    let startYear = currentYear - 10;
    const years: string[] = [];

    while (startYear <= currentYear) {
      years.push((startYear++).toString());
    }

    return years.reverse();
  }
}
